import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import DataTable from "react-data-table-component";
import Button from "@mui/material/Button";
import { CSVLink } from "react-csv";
import { Box, Grid, TextField, MenuItem, Select } from "@mui/material";

export const PaymentTransactions = () => {
    const [data, setData] = useState([]);
    const [allItems, setAllItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [filterText, setFilterText] = useState("");
    const [shownStatuses, setShownStatuses] = useState([]);
    const [searchColumn, setSearchColumn] = useState("all");

    const fetchData = async (fPerPage = 10, fPage = 1, fShownStatuses = [], loadAll = false) => {
        setLoading(true);
        try {
            const response = await axios.get("/internal/paymentus_transactions", {
                params: {
                    shown_status : loadAll ? [].toString() : fShownStatuses.toString(),
                    per_page: loadAll ? totalRows : fPerPage,
                    page: loadAll ? 1 : fPage,

                },
                headers: { "x-api-key": process.env.REACT_APP_API_KEY },
            });
            if (loadAll) {
                setAllItems(response.data.data);
            } else {
                setData(response.data.data);
                setTotalRows(response.data.total);
                console.log('setting total');
                console.log(response.data.total);

            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(perPage, currentPage, shownStatuses);
    }, [perPage, currentPage]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const loadAllItems = async () => {
        await fetchData();
    };

    const setFilterStatuses =  async (status) => {
        switch (status) {
            case "pending":
                await setShownStatuses([1]);
                await fetchData(perPage, currentPage, [1]);
                break;
            case "deleted":
                await setShownStatuses([0]);
                await fetchData(perPage, currentPage, [0]);
                break;
            case "processed":
                await setShownStatuses([2]);
                await fetchData(perPage, currentPage, [2]);
                break;
            case "other":
                await setShownStatuses([3]);
                await fetchData(perPage, currentPage, [3]);
                break;
            case "duplicate":
                await setShownStatuses([4]);
                await fetchData(perPage, currentPage, [4]);
                break;
            case "all":
            default:
                await setShownStatuses([]); // Show all statuses
                await fetchData(perPage, currentPage, []);
                break;
        }
    };


    const onFilter = (e) => {
        setFilterText(e.target.value);
    };

    const filteredItems = (allItems.length ? allItems : data).filter((item) => {
        if (shownStatuses.length && !shownStatuses.includes(item.status)) return false;
        if (!filterText) return true;
        const value = filterText.toLowerCase();
        switch (searchColumn) {
            case "source":
                return item.source.toLowerCase().includes(value);
            case "paymentType":
                return item.paymentType.toLowerCase().includes(value);
            case "paymentMethodType":
                return item.paymentMethodType?.toLowerCase().includes(value);
            case "paymentMethodCardNumber":
                return String(item.paymentMethodCardNumber).includes(value);
            case "accountNumber":
                return String(item.accountNumber).includes(value);
            case "all":
            default:
                return (
                    item.source.toLowerCase().includes(value) ||
                    item.paymentType.toLowerCase().includes(value) ||
                    item.paymentMethodType.toLowerCase().includes(value) ||
                    String(item.status).includes(value)
                );
        }
    });

    const columns = [
        // { name: "ID", selector: "id", sortable: true },
        { name: "Source", selector: row=> row.source, sortable: true },
        { name: "Status", selector: row=> row.status, sortable: true },
        { name: "Created At", selector: row=> row.created_at, sortable: true },
        { name: "Updated At", selector: row=> row.updated_at, sortable: true },
        { name: "Reference Number", selector: row=> row.referenceNumber, sortable: true },
        { name: "Payment Date", selector: row=> row.paymentDate, sortable: true },
        { name: "Payment Type", selector: row=> row.paymentType, sortable: true },
        { name: "Payment Status", selector: row=> row.paymentStatus, sortable: true },
        { name: "Account Number", selector: row=> row.accountNumber, sortable: true },
        { name: "Amount", selector: row=> row.amount, sortable: true },
        { name: "Payment Method Type", selector: row=> row.paymentMethodType, sortable: true },
        { name: "Payment Method Card Number", selector: row=> row.paymentMethodCardNumber, sortable: true },
        { name: "Customer First Name", selector: row=> row.customerFirstName, sortable: true },
        { name: "Customer Middle Name", selector: row=> row.customerMiddleName, sortable: true },
        { name: "Customer Last Name", selector: row=> row.customerLastName, sortable: true },
        { name: "Customer Email", selector: row=> row.customerEmail, sortable: true },
        { name: "Customer Day Phone", selector: row=> row.customerDayPhone, sortable: true },
        { name: "Customer Zip Code", selector: row=> row.customerZipCode, sortable: true },
        { name: "Customer Country", selector: row=> row.customerCountry, sortable: true },
    ];

    const exportHeaders = [
        { label: "ID", key: "id" },
        { label: "Source", key: "source" },
        { label: "Status", key: "status" },
        { label: "Created At", key: "created_at" },
        { label: "Updated At", key: "updated_at" },
        { label: "Reference Number", key: "referenceNumber" },
        { label: "Payment Date", key: "paymentDate" },
        { label: "Payment Type", key: "paymentType" },
        { label: "Payment Status", key: "paymentStatus" },
        { label: "Account Number", key: "accountNumber" },
        { label: "Amount", key: "amount" },
        { label: "Payment Method Type", key: "paymentMethodType" },
        { label: "Payment Method Card Number", key: "paymentMethodCardNumber" },
        { label: "Customer First Name", key: "customerFirstName" },
        { label: "Customer Middle Name", key: "customerMiddleName" },
        { label: "Customer Last Name", key: "customerLastName" },
        { label: "Customer Email", key: "customerEmail" },
        { label: "Customer Day Phone", key: "customerDayPhone" },
        { label: "Customer Zip Code", key: "customerZipCode" },
        { label: "Customer Country", key: "customerCountry" },
    ];

    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <h1>Paymentus Transactions Received</h1>
                </Grid>
                <Grid item>
                    <Button onClick={() => setFilterStatuses("all")}>All</Button>
                    <Button onClick={() => setFilterStatuses("pending")}>Pending</Button>
                    <Button onClick={() => setFilterStatuses("deleted")}>Deleted</Button>
                    <Button onClick={() => setFilterStatuses("processed")}>Processed</Button>
                    <Button onClick={() => setFilterStatuses("other")}>Other</Button>
                    <Button onClick={() => setFilterStatuses("duplicate")}>Duplicate</Button>
                </Grid>

            </Grid>
            <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                <Grid item xs={9}>
                    <TextField
                        label="Search"
                        variant="outlined"
                        fullWidth
                        value={filterText}
                        onChange={onFilter}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Select
                        value={searchColumn}
                        onChange={(e) => setSearchColumn(e.target.value)}
                        fullWidth
                    >
                        <MenuItem value="all">All Columns</MenuItem>
                        <MenuItem value="source">Source</MenuItem>
                        <MenuItem value="paymentType">Payment Type</MenuItem>
                        <MenuItem value="paymentMethodType">Payment Method Type</MenuItem>
                        <MenuItem value="paymentMethodCardNumber">Last Four</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            <Grid item>
                {allItems.length === 0 ? (
                    <Button onClick={loadAllItems} variant="contained" color="primary">
                        Load all for download
                    </Button>
                ) : (
                    <CSVLink
                        data={allItems}
                        headers={exportHeaders}
                        filename={"payment_transactions.csv"}
                        className="btn btn-primary"
                    >
                        Download CSV
                    </CSVLink>
                )}
            </Grid>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <DataTable
                    title={"Payment Transactions"}
                    columns={columns}
                    data={filteredItems}
                    pagination
                    responsive
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    paginationDefaultPage={currentPage}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={setPerPage}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                />
            )}
        </div>
    );
};
